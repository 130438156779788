import Cookies from 'js-cookie';

let html = null;

export default class Page {
	constructor() {
		console.log('Page');

		this.textSizeContainer = document.getElementById('text_size');

		this.bindEvents();

		this.currentTextSize = 'text100';

		if ( Cookies.get('textSize') )
		{
			this.currentTextSize = Cookies.get('textSize');
			this.setTextSize( this.currentTextSize );
		}
	}

	bindEvents () {
		this.textSizeContainer.addEventListener('click', event => {
			if ( event.target.nodeName.toLowerCase() !== 'button' )
			{
				return false;
			}

			this.setTextSize(event.target.dataset.size)
		});

		let mql = window.matchMedia("(min-width: 768px)");

		try {
			// Chrome & Firefox
			mql.addEventListener('change', (e) => {
				this.mqlChange(e);
			});
		} catch (e1) {
			try {
				// Safari
				mql.addListener((e) => {
					this.mqlChange(e);
				});
			} catch (e2) {
				console.error(e2);
			}
		}
	}

	mqlChange ( e ) {
		// make sure that we can't be at 200% in smaller than 768px
		if ( !e.matches && this.currentTextSize === 'text200' )
		{
			this.setTextSize('text150');
		}
	}

	setTextSize ( size ) {
		Page.removeClass(  Page.getHtml(), 'text100'  );
		Page.removeClass(  Page.getHtml(), 'text150'  );
		Page.removeClass(  Page.getHtml(), 'text200'  );

		Page.addClass( Page.getHtml(), size );
		this.currentTextSize = size;
		Cookies.set('textSize', size);
	}

	static getHtml ()
	{
		if ( html === null )
		{
			html = document.querySelector('html');
		}
		return html;
	}

	static addClass (el, className) {
		if ( !el.classList.contains(className) )
		{
			el.classList.add(className);
		}
	}

	static removeClass (el, className) {
		if ( el.classList.contains(className) )
		{
			el.classList.remove(className);
		}
	}

	static hasClass (el, className) {
		return el.classList.contains(className);
	}

}