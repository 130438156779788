import Page from "./Page";



class Main
{
    constructor ()
    {
        switch( PAGE_NAME )
        {
            default:
                new Page();
                break;
        }
    }


}


function ready(callbackFunc) {
    if (document.readyState !== 'loading') {
        // Document is already ready, call the callback directly
        callbackFunc();
    } else if (document.addEventListener) {
        // All modern browsers to register DOMContentLoaded
        document.addEventListener('DOMContentLoaded', callbackFunc);
    } else {
        // Old IE browsers
        document.attachEvent('onreadystatechange', function() {
            if (document.readyState === 'complete') {
                callbackFunc();
            }
        });
    }
}


ready(function() {
    new Main();
});
